<template>
  <section>
    <div
      v-href
      class="mb-4 overflow-hidden rounded-2xl bg-white"
    >
      <div class="relative h-36">
        <UiImg
          :image="product.active_image"
          :alt="product.title"
          class="size-full object-cover"
        />
        <button
          v-if="likable"
          class="absolute right-0 top-0 m-2 flex size-8 items-center justify-center rounded-md border-2 border-white bg-white hover:border-primary"
          @click.stop="handleClickLike"
        >
          <font-awesome-icon
            :icon="[isFavorite ? 'fas' : 'far', 'heart']"
            class="text-primary"
          />
        </button>
      </div>
      <div class="p-4">
        <nuxt-link
          :to="product.url"
          class="block truncate font-bold text-primary hover:no-underline"
        >
          {{ product.title }}
        </nuxt-link>
        <div class="mt-2 text-sm leading-snug">
          {{ product.hoeveelheid }} {{ product.eenheid_hoeveel }}<br>
          {{ sizes }}<br>
          {{ product.plaats }}
        </div>
      </div>
    </div>
    <SaveFavorite
      v-if="likable"
      :visible="openModal"
      :product="product"
      @event:close="openModal = false"
    />
  </section>
</template>

<script>
import SaveFavorite from '~/components/general/SaveFavorite';

export default {
  components: {
    SaveFavorite,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    likable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useFavoritesStore();
    const openModal = ref(false);
    const isFavorite = computed(() => store.isFavorite(props.product));
    const sizes = useProductSize(() => props.product);

    return {
      isFavorite,
      openModal,
      handleClickLike() {
        if (isFavorite.value) {
          store.removeFavorite(props.product);
        } else {
          openModal.value = false;
        }
      },
      sizes,
    };
  },
  data: () => ({
    liked: false,
  }),
};
</script>
