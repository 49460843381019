<template>
  <section>
    <UiModal
      title="Favoriet opslaan"
      :open="visible"
      size="small"
    >
      <FormKit
        :actions="false"
        method="post"
        type="form"
        class="space-y-8 pb-6"
        @submit="submit"
      >
        <FormKit
          v-model="selectedList"
          name="list"
          type="select"
          label="Materiaal opslaan in"
          :options="favoritesLists"
          @update:active-sort="updateSelectedList"
        />
        <FormKit
          v-if="showNewListInput"
          v-model="newListName"
          label="Naam voor nieuwe favorietenlijst"
          type="text"
          name="name"
          required
        />
        <div class="mt-1 flex items-center space-x-10 text-left">
          <button
            type="submit"
            class="btn btn-secondary block"
          >
            Opslaan
          </button>
          <button
            type="button"
            class="font-semibold text-secondary underline hover:text-secondary-light"
            @click="$emit('event:close');"
          >
            Annuleren
          </button>
        </div>
      </FormKit>
    </UiModal>
  </section>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['event:close'],
  setup() {
    const user = useSofieUserWhenAuthenticated();

    const store = useFavoritesStore();

    const {result} = useFavoritesListsQuery(() => ({
      enabled: !!user.value,
    }));

    const favoritesLists = computed(() => {
      const transformedList = result.value?.favoritesLists.map((l) => {
        return {label: l.title, value: l.uuid};
      }) ?? [];

      transformedList.push({
        label: 'Nieuwe favorietenlijst...',
        value: 0,
      });

      return transformedList;
    });

    const {client: apollo} = useApolloClient();

    return {
      apollo,
      favoritesLists,
      store,
      user,
    };
  },
  data: () => ({
    selectedList: 0,
    newListName: null,
  }),
  computed: {
    showNewListInput() {
      return this.selectedList === 0;
    },
  },
  watch: {
    visible() {
      if (!this.visible || !!this.user) {
        return;
      }

      this.store.addFavorite(this.product);
      $toast.success('Het materiaal is toegevoegd aan je favorietenlijst.');
      this.$emit('event:close');
    },
  },
  methods: {
    async submit(formData) {
      try {
        let listId = formData.list;

        if (this.showNewListInput) {
          const {data} = await this.apollo.mutate({
            mutation: NewFavoritesListDocument,
            variables: {
              title: formData.name,
            },
          });

          listId = data.newFavoritesList.uuid;
        }

        await this.apollo.mutate({
          mutation: AddProductToListDocument,
          variables: {
            product_slug: this.product.slug,
            list_uuid: listId,
          },
          refetchQueries: [{
            query: FavoritesListsDocument,
          }],
        });

        this.$emit('event:close');
        $toast.success('Het materiaal is toegevoegd aan je favorietenlijst.');
      } catch (e) {
        console.error(e);
        this.$toast.error('Er is iets mis gegaan tijdens het toevoegen van het product. Probeer het later nog eens.');
      }
    },
    updateSelectedList(filter) {
      this.selectedList = filter.option;
    },
  },
};
</script>
